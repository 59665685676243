import React, { Component } from 'react';
import Portfolio from './components/Portfolio';

class App extends Component {
  render() {
    return (
      <Portfolio />
    );
  }
}

 
export default App;
